import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AdminHomeBanner = () => {
    
  // Ck Editor Code
  const [Desc, setDesc] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


//Frontend Integration
  
  const [BannerImage, setBannerImage] = useState("");
  const [BannerText, setBannerText] = useState("");

  //POST

  const createHomeBanner = async () => {
    try {
      const config = {
        url: "/createhome",
        method: "post",
        baseURL: "https://students-databse.in/api/homebanner",
        headers: { "content-type": "multipart/form-data" },
        data: {
          bannerImg: BannerImage,         
          bannerText: BannerText,
         
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getHomeBanner();
        setBannerImage("");      
        setBannerText("");       
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };

//GET

  const [Banner, setBanner] = useState([]);

  const getHomeBanner = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/homebanner/gethome");
      if (res.status === 201) {
        setBanner(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

  //UPDATE

const [HomeBannerId, setHomeBannerId] = useState({})

  const editHomeBanner = async()=>{
    try {
    const config = {
      url: "/updatehome/"+HomeBannerId?._id,
      method: "put",
      baseURL: "https://students-databse.in/api/homebanner",
      headers: { "content-type": "multipart/form-data" },
      data: {
        bannerImg: BannerImage,     
        bannerText: BannerText,
       
      },
    };
    
    let res = await axios(config);
    if (res.status === 201) {
      alert(res.data.sucess);
      handleClose4()
      getHomeBanner();
      setBannerImage("");    
      setBannerText("");
    }
  }
   catch (error) {
    alert(error.response.data.error);
  }
  }


  //DELETE

  const deleteHomeBanner= async () => {
    try {
      let res = await axios.delete(`https://students-databse.in/api/homebanner/deletehome/${HomeBannerId?._id}`);
      if (res.status === 201) {
        alert(res.data.message);
        getHomeBanner();
        handleClose5()
      }
    } catch (error) {
      alert(error.response.data.message)
    }
  };

  useEffect(() => {
    getHomeBanner();
  }, []);
 

  return (
    <div>


    <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Home Banner</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Home Banner
            </button>
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Banner Image</th>          
                <th>Text</th>              
                <th>Action</th>
              </tr>
            </thead>

            <tbody>


            {Banner?.map((item, i) => {
                return (
                  <tr>
                    <td>{i+1}</td>
                    <td>
                        
                      <img
                        src={`https://students-databse.in/HomeBanner/${item?.bannerImg}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />{" "}
                    </td>
                  
                    <td style={{ paddingTop: "10px" }}>{item?.bannerText}</td>

                    
                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(9,122,215)",
                            }}
                            onClick={() =>{
                              handleShow4()
                              setHomeBannerId(item)
                            }                               
                             }
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(172,172,172)",
                            }}
                            onClick={() => {
                              handleShow5();

                              setHomeBannerId(item)
                              
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
               );
              })}

            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}

        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Home Banner</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Banner Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  accept="image/*"
                  onChange={(e) => setBannerImage(e.target.files[0])}
                />
              </div>
            </div>
          

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Banner Text</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter the text"
                 onChange={(e) => setBannerText(e.target.value)}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={createHomeBanner}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}

        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Home Slider
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Banner Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                    onChange={(e) => setBannerImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Banner Text</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter the text"
                   onChange={(e) => setBannerText(e.target.value)}
                />
              </div>
            </div>

           
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={editHomeBanner}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delete Package modal for Slider */}

        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br/> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
               onClick={deleteHomeBanner}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

    </div>
  )
}

export default AdminHomeBanner