import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";

const AdminAboutBanner = () => {

    // Ck Editor Code
  const [Desc, setDesc] = useState()

  const handleChange = (e, editor) => {
    const data=editor.getData(); 
    setDesc(data) 
  }

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


//Frontend Integration
// const [BannerImage, setBannerImage] = useState("");
// const [BannerText, setBannerText] = useState("");

//Post

// const postAboutBanner = async () => {
//   if(!BannerImage){
//     return alert ("Please Select Image...")
//   }
  
//   try {
//     const config = {
//       url: "/createaboutbanner",
//       method: "post",
//       baseURL: "https://students-databse.in/api/aboutbanner",
//       headers: { "content-type": "multipart/form-data" },
//       data: {
//         bannerImg: BannerImage,
       
//         bannerText: BannerText,
        
//       },
//     };

//     let res = await axios(config);
//     if (res.status === 200) {
//       alert(res.data.status);
//       handleClose3();
//       getAboutBanner();

//       setBannerImage("");     
//       setBannerText("");
      
//     }
//   } catch (error) {
//     alert(error.response.data.error);
//   }
// };


//Get

// const [Banner, setBanner] = useState(null);

// const getAboutBanner = async () => {
//   try {
//     let res = await axios.get("https://students-databse.in/api/aboutbanner/getaboutbanner");
//     if (res.status === 201) {
//       setBanner(res.data.data1);

//       console.log(res.data.data1);
      
//     }
//   } catch (error) {
//     console.log(error,"data some data");
//   }
// };

// console.log("Banner: ", Banner)

//Edit

// const [AboutBannerId, setAboutBannerId] = useState({})
// const editAboutBanner = async()=>{
//   try {
//   const config = {
//     url: "/updateaboutbanner/"+AboutBannerId?._id,
//     method: "put",
//     baseURL: "https://students-databse.in/api/aboutbanner",
//     headers: { "content-type": "multipart/form-data" },
//     data: {
//       bannerImg: BannerImage,  
//       bannerText: BannerText    
//     },
//   };

//   let res = await axios(config);
//   if (res.status === 201) {
//     alert(res.data.success);
//     handleClose4()
//     getAboutBanner();

//     setBannerImage("");
    
//     setBannerText("");
   
//   }
// }
//  catch (error) {
//   alert(error.response.data.error);
// }
// }

//Delete

// const deleteAboutBanner = async () => {
//   try {
//     let res = await axios.delete(`https://students-databse.in/api/aboutbanner/deleteaboutbanner/${AboutBannerId?._id}`);
//     if (res.status === 201) {
//       alert(res.data.message);
//       getAboutBanner();
//       handleClose5()
//     }
//   } catch (error) {
//     alert(error.response.data.message)
//   }
// };

// useEffect(() => {
//   getAboutBanner();
// }, []);


  return (
    <div>
        
      <div className="customerhead p-2">
                 <div className="d-flex justify-content-between align-items-center">
            <h2 className="header-c ">About Banner</h2>
            <div className="d-flex gap-3">
              {/* {Banner?.length ? (""):( */}
                <button className="admin-add-btn" onClick={handleShow3}>
                Add About Banner
              </button>
              {/* )} */}
              
            </div>

          </div>

          <div className="mb-3">
            <Table
              responsive
              bordered
              style={{ width: "-webkit-fill-available" }}
            >
              <thead>
                <tr>
                  
                  <th>Banner Image</th>
                 
                  <th>Text</th>
                 
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>

              {/* {Banner?.map((item, i) => {
                return ( */}
                <tr>
                 
                  <td>img
                    {/* <img
                          src={`https://students-databse.in/AboutBanner/${item?.bannerImg}`}
                          alt="pic"
                          style={{ width: "75px", height: "75px" }}
                        /> {" "} */}
                  </td>
                  
                  <td style={{ paddingTop: "10px" }}>text</td>
              
                  <td>
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "20px",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <BiSolidEdit
                          className=""
                          style={{ cursor: "pointer", fontSize: "20px", color:"rgb(9,122,215)" }}
                          onClick={() => {
                            handleShow4()
                            // setAboutBannerId(item)
                            }
                          }

                        />{" "}
                      </div>
                      <div>
                        <AiFillDelete
                          className=""
                          style={{ cursor: "pointer", fontSize: "20px", color:"rgb(25, 107, 156)" }}
                          onClick={() => {
                            handleShow5();
                            // setAboutBannerId(item)
                            // setDatas(item?._id);
                          }}
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                {/* );
            })} */}
              </tbody>
            </Table>
            </div>

            {/* Add Package modal for Slider */}
            <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
              <Modal.Header
                closeButton
              >
                <Modal.Title>Add About Banner</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Banner Image</label>
                    <input
                      type="file"
                      name=""
                      id=""
                      className="vi_0"
                    //  onChange={(e) => setBannerImage(e.target.files[0])}
                    />
                  </div>
                </div>
                

                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Add Banner Text</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter Banner Title"
                    // onChange={(e) => setBannerText(e.target.value)}
                    />
                  </div>
                </div>

                

              </Modal.Body>
              <Modal.Footer>
                <div className="d-flex">
                  <Button
                    className="mx-2 modal-close-btn"
                    variant=""
                    onClick={handleClose3}
                  >
                    Close
                  </Button>
                  <Button
                    className="mx-2 modal-add-btn"
                    variant=""
                //   onClick={postAboutBanner}
                  >
                    Add
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>

            {/* Edit Package modal for Slider */}
            <Modal
              show={show4}
              onHide={handleClose4}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header
                closeButton
              >
                <Modal.Title style={{ color: "black" }}>Edit About Banner</Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Banner Image</label>
                    <input
                      type="file"
                      name=""
                      id=""
                      className="vi_0"
                    // onChange={(e) => setBannerImage(e.target.files[0])}
                    />
                  </div>
                </div>


                <div className="row">
                  <div className="do-sear mt-2">
                    <label>Edit Banner Text</label>
                    <input
                      type="text"
                      className="vi_0"
                      placeholder="Enter Banner Title"
                    //  onChange={(e) => setBannerText(e.target.value)}
                    />
                  </div>
                </div>


              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose4}
                >
                  Close
                </Button>
                <Button variant="" className="modal-add-btn"
                // onClick={editAboutBanner}
                >
                  Update
                </Button>
              </Modal.Footer>
            </Modal>

            {/*Delet Package modal for Slider */}
            <Modal
              show={show5}
              onHide={handleClose5}
              backdrop="static"
              keyboard={false}
              style={{ zIndex: "99999" }}
            >
              <Modal.Header
                closeButton
              >
                <Modal.Title>Warning</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="row">
                  <div className="col-md-12">
                    <p className="fs-4" style={{ color: "red" }}>
                      Are you sure?
                      <br /> you want to delete this data?
                    </p>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant=""
                  className="modal-close-btn"
                  onClick={handleClose5}
                >
                  Close
                </Button>
                <Button
                  variant=""
                  className="modal-add-btn"
                // onClick={deleteAboutBanner}
                >
                  Delete
                </Button>
              </Modal.Footer>
            </Modal>
      </div>
    </div>
  )
}

export default AdminAboutBanner