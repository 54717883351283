import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const AdminServiceDescription = () => {
  
  // Ck Editor Code
  const [Desc, setDesc] = useState("");

  //get
  const [description,setDescription]=useState([])
  const [selectedObj,setSelectedObj]=useState({})


  //edit
  const [editObj,setEditObj]=useState({})
  const [editableDesc,setEditableDesc]=useState("")

  useEffect(()=>{
    setDesc(editObj?.description)
  },[editObj])


  const handleChange = (e, editor) => {
    const data = editor.getData();
    // const plainText = data.replace(/<[^>]*>/g, '');
    setDesc(data);
  };

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


//POST

const saveDescription=async()=>{
  try{
    let res = await axios.post("https://students-databse.in/api/service/createdescription",{description:Desc});
    if (res.status === 200) {
      handleClose3()
      getServiceDesc()
      alert('Description Saved..')
   
    }
    if (res.status === 400) {
      alert("Failed to save description")
    }
  }
  catch(error){
    console.log(error)
  }
}


//GET

const getServiceDesc=async()=>{
  try{
    let res = await axios.get("https://students-databse.in/api/service/getdescription");
    if (res.status === 201) {
      setDescription(res.data.data1);
    }
    if (res.status === 400) {
      alert("Failed to fetch Description")
    }
    if (res.status === 500) {
      alert("Something went wrong")
    }
  }
  catch(error){
    console.log(error)
  }
}

//DELETE

const deleteDesc=async()=>{
  setShow5(false)
  try{
    const res = await axios.delete(`https://students-databse.in/api/service/deletedescription/${selectedObj?._id}`)
    if(res.status===201){
      alert('Successfully deleted..')
      getServiceDesc()
    }
    if(res.status===400){
      alert('Failed to delete..')
    }
    if(res.status===500){
      alert('Failed to delete..')
    }
  }catch(error){
    console.log(error);
    
  }
}


//UPDATE

const updateDescription=async()=>{
  setShow4(false)
  try{
    const res = await axios.put(`https://students-databse.in/api/service/updatedescription/${editObj?._id}`,{
      description:Desc
    })
    if(res.status===201){
      alert('Successfully Updated..')
      setShow4(false)
      getServiceDesc()
    }
    if(res.status===400){
      alert('Failed to update..')
    }
  }catch(error){
    console.log(error);
    
  }
}


useEffect(()=>{
  getServiceDesc()
},[])

  return (
    
    <div>
         <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Description</h2>
          {
            description.length > 0 ? (''):(
              <div className="d-flex gap-3">
              <button className="admin-add-btn" onClick={handleShow3}>
                Add Description
              </button>
            </div>
            )
          }
 
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
               
             
                <th>Description</th>
               
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {
                description.map((data)=>{
                  return(
                    <>
                              <tr>
 
 <td style={{ paddingTop: "10px" }}>{parse(`<div>${data?.description}</div>`)}</td>

 <td>
   {" "}
   <div
     style={{
       display: "flex",
       gap: "20px",
       justifyContent: "center",
     }}
   >
     <div>
       <BiSolidEdit
         className=""
         style={{
           cursor: "pointer",
           fontSize: "20px",
           color: "rgb(9,122,215)",
         }}
         onClick={() =>{
           handleShow4()
         //   setHomeBannerId(item)
         setEditObj(data)
         }
            
           }
       />{" "}
     </div>
     <div>
       <AiFillDelete
         className=""
         style={{
           cursor: "pointer",
           fontSize: "20px",
           color: "rgb(172,172,172)",
         }}
         onClick={() => {
           handleShow5();
           setSelectedObj(data)
         }}
       />
     </div>
   </div>
 </td>
</tr>
                    </>
                  )
                })
              }
             
        
               
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}

        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description</label>
               
                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                // onClick={AdminSlider}
                onClick={saveDescription}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}

        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Description
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Description</label>
               
                <CKEditor editor={ClassicEditor} data={Desc}  onChange={handleChange} />
              </div>
            </div>
           
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
            //   onClick={EditHomeSlider}
            onClick={updateDescription}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}

        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
         onClick={deleteDesc}

            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default AdminServiceDescription