import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

const AdminProjectCategory = () => {

    // Ck Editor Code
  const [Desc, setDesc] = useState()

  const handleChange = (e, editor) => {
    const data=editor.getData(); 
    setDesc(data) 
  }

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delet modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

//Frontend Integration
  
const [CategoryText, setCategoryText] = useState("");

//POST

const createProjectCategoryText = async () => {
  try {
    const config = {
      url: "/createprojectcategory",
      method: "post",
      baseURL: "https://students-databse.in/api/project",
      headers: { "content-type": "application/json" },
      data: {
        projecttext: CategoryText,
      },
    };

    let res = await axios(config);
    if (res.status === 200) {
      alert(res.data.status);
      handleClose3();
      getProjectCategoryText();
      setCategoryText("");       
    }
  } catch (error) {
    alert(error.response.data.error);
    console.log(error)
  }
};

//GET

const [ProjectCategoryText, setProjectCategoryText] = useState([]);

const getProjectCategoryText = async () => {
  try {
    let res = await axios.get("https://students-databse.in/api/project/getprojectcategory");
    if (res.status === 201) {
      setProjectCategoryText(res.data.data1);
    }
  } catch (error) {
    alert(error.response.data.error); 
  }
};


//UPDATE

const [ProjectCategoryTextId, setProjectCategoryTextId] = useState({})

const editProjectCategoryText = async()=>{
  try {
  const config = {
    url: "/updateprojectcategory/"+ProjectCategoryTextId?._id,
    method: "put",
    baseURL: "https://students-databse.in/api/project",
    headers: { "content-type": "application/json" },
    data: {
        
      projecttext: CategoryText,
     
    },
  };
  
  let res = await axios(config);
  if (res.status === 201) {
    alert(res.data.status);
    handleClose4()  
    setCategoryText("");
  }
}
 catch (error) {
  alert(error.response.data.error);
}
}

//DELETE

const deleteProjectCategoryText = async () => {
  try {
    let res = await axios.delete(`https://students-databse.in/api/project/deleteprojectcategory/${ProjectCategoryTextId?._id}`);
    if (res.status === 201) {
      alert(res.data.message);
      getProjectCategoryText();
      handleClose5()
    }
  } catch (error) {
    alert(error.response.data.message)
  }
};


useEffect(() => {
  getProjectCategoryText();
}, []);


  return (
    <div>  <div className="customerhead p-2">
    <div className="d-flex justify-content-between align-items-center">
<h2 className="header-c ">Category</h2>
<div className="d-flex gap-3">
 <button className="admin-add-btn" onClick={handleShow3}>
   Add Category 
 </button>
</div>

</div>

<div className="mb-3">
<Table
 responsive
 bordered
 style={{ width: "-webkit-fill-available" }}
>
 <thead>
   <tr>
     <th></th>
     <th>Category</th>
    
     <th>Action</th>
   </tr>
 </thead>
 <tbody>

     {
ProjectCategoryText.map((data,index)=>{
return(

  <tr>
     <td>{index+1}</td>
     <td style={{ paddingTop: "10px" }}>{data?.projecttext}</td>
     
     <td>
       {" "}
       <div
         style={{
           display: "flex",
           gap: "20px",
           justifyContent: "center",
         }}
       >
         <div>
           <BiSolidEdit
             className=""
             style={{ cursor: "pointer", fontSize: "20px", color:"rgb(101, 177, 69)" }}
             onClick={() => {
              handleShow4();
              setProjectCategoryTextId(data)
              }
             }

           />{" "}
         </div>

         <div>
           <AiFillDelete
             className=""
             style={{ cursor: "pointer", fontSize: "20px", color:"rgb(25, 107, 156)" }}
             onClick={() => {
               handleShow5();
               setProjectCategoryTextId(data)
             }}
           />
         </div>

       </div>
     </td>
   </tr>

     )
    })
    }  
   
 {/* );
})} */}
 </tbody>
</Table>
</div>

{/* Add Package modal for Slider */}
<Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
 <Modal.Header
   closeButton
 >
   <Modal.Title>Add Category </Modal.Title>
 </Modal.Header>
 <Modal.Body>
   
   
   <div className="row">
     <div className="do-sear mt-2">
       <label>Add Category Name</label>
       <input
         type="text"
         className="vi_0"
         placeholder="Enter Category Name"
       
       // value={category}
       onChange={(e)=>setCategoryText(e.target.value)}
       />
     </div>
   </div>
   

 </Modal.Body>
 <Modal.Footer>
   <div className="d-flex">
     <Button
       className="mx-2 modal-close-btn"
       variant=""
       onClick={handleClose3}
     >
       Close
     </Button>
     <Button
       className="mx-2 modal-add-btn"
       variant=""
       onClick={createProjectCategoryText}
     >
       Add
     </Button>
   </div>
 </Modal.Footer>
</Modal>

{/* Edit Package modal for Slider */}
<Modal
 show={show4}
 onHide={handleClose4}
 backdrop="static"
 keyboard={false}
 style={{ zIndex: "99999" }}
>
 <Modal.Header
   closeButton
 >
   <Modal.Title style={{ color: "black" }}>Edit Category </Modal.Title>
 </Modal.Header>

 <Modal.Body>
  


   <div className="row">
     <div className="do-sear mt-2">
       <label>Edit Caterory Title</label>
       <input
         type="text"
         className="vi_0"
         //value={editupdate}
         placeholder="Enter Category Name"
        onChange={(e) => setCategoryText(e.target.value)}
       />
     </div>
   </div>


 </Modal.Body>
 <Modal.Footer>
   <Button
     variant=""
     className="modal-close-btn"
     onClick={handleClose4}
   >
     Close
   </Button>
   <Button variant="" className="modal-add-btn"
  onClick={editProjectCategoryText}
   >
     Update
   </Button>
 </Modal.Footer>
</Modal>

{/*Delete Package modal for Slider */}
<Modal
 show={show5}
 onHide={handleClose5}
 backdrop="static"
 keyboard={false}
 style={{ zIndex: "99999" }}
>
 <Modal.Header
   closeButton
 >
   <Modal.Title>Warning</Modal.Title>
 </Modal.Header>
 <Modal.Body>
   <div className="row">
     <div className="col-md-12">
       <p className="fs-4" style={{ color: "red" }}>
         Are you sure?
         <br /> you want to delete this data?
       </p>
     </div>
   </div>
 </Modal.Body>
 <Modal.Footer>
   <Button
     variant=""
     className="modal-close-btn"
     onClick={handleClose5}
   >
     Close
   </Button>
   <Button
     variant=""
     className="modal-add-btn"
     onClick={deleteProjectCategoryText}
   >
     Delete
   </Button>
 </Modal.Footer>
</Modal>
</div>
        
</div>
)
}

export default AdminProjectCategory
