import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AdminAboutDescription = () => {

     // Ck Editor Code
  const [Desc, setDesc] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


//Frontend Integration
  
const [AboutImage, setAboutImage] = useState("");


//POST

const createAboutDescription = async () => {

  try {
    const config = {
      url: "/createaboutdescription",
      method: "post",
      baseURL: "https://students-databse.in/api/aboutdescription",
      headers: { "content-type": "multipart/form-data" },
      data: {
        image: AboutImage,         
        description: Desc,
       
      },
    };

    let res = await axios(config);
    if (res.status === 200) {
      alert(res.data.status);
      handleClose3();
      getAboutDescription();
      setAboutImage("");      
      setDesc("");       
    }
  } catch (error) {
    alert(error.response.data.error);
  }
};

//GET

const [AboutDescription, setAboutDescription] = useState([]);

const getAboutDescription= async () => {
  try {
    let res = await axios.get("https://students-databse.in/api/aboutdescription/getaboutdescription");
    if (res.status === 201) {
      setAboutDescription(res.data.data1);
    }
  } catch (error) {
    alert(error.response.data.error); 
  }
};

//UPDATE

const [AboutDescriptionId, setAboutDescriptionId] = useState({})

const editAboutDescription = async()=>{
  try {
  const config = {
    url: "/updateaboutdescription/"+AboutDescriptionId?._id,
    method: "put",
    baseURL: "https://students-databse.in/api/aboutdescription",
    headers: { "content-type": "multipart/form-data" },
    data: {
      image: AboutImage,     
      description: Desc,
     
    },
  };
  
  let res = await axios(config);
  if (res.status === 201) {
    alert(res.data.success);
    handleClose4()
    getAboutDescription();
    setAboutDescription("");    
    setDesc("");
  }
}
 catch (error) {
  alert(error.response.data.error);
}
}

//DELETE

const deleteAboutDescription= async () => {
  try {
    let res = await axios.delete(`https://students-databse.in/api/aboutdescription/deleteaboutdescription/${AboutDescriptionId?._id}`);
    if (res.status === 201) {
      alert(res.data.message);
      getAboutDescription();
      handleClose5()
    }
  } catch (error) {
    alert(error.response.data.message)
  }
};

useEffect(() => {
  getAboutDescription();
}, []);


  return (
    <div>
          <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">About Description</h2>
          <div className="d-flex gap-3">
          {/* {AboutDescription?.length ? (""):( */}
            <button className="admin-add-btn" onClick={handleShow3}>
              Add About Description
            </button>
            {/*  )} */}
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Image</th>
             
                <th>Description</th>
               
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
             
            {AboutDescription?.map((item, i) => {
                return (
                  <tr>
                    <td></td>
                    <td>
                      <img
                        src={`https://students-databse.in/AboutDescription/${item?.image}`}
                        alt="pic"
                        style={{ width: "75px", height: "75px" }}
                      />{" "}
                    </td>
                  
                    {/* <td style={{ paddingTop: "10px" }}>{parse(item?.description)}</td> */}

                    <td style={{ paddingTop: "10px" }}>
                      {typeof item?.description === 'string' ? parse(item.description) : null}
                    </td>

                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(9,122,215)",
                            }}
                            onClick={() =>{
                              handleShow4()
                             setAboutDescriptionId(item)
                            }
                               
                              }
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(172,172,172)",
                            }}
                            onClick={() => {
                              handleShow5();

                               setAboutDescriptionId(item)
                              // setDatas(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}

            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}

        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Description</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  accept="image/*"
                  onChange={(e) => setAboutImage(e.target.files[0])}
                />
              </div>
            </div>
          
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description</label>
               
                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                 onClick={createAboutDescription}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}

        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Description
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Image</label>
                <input
                  type="file"
                  name=""
                  id=""
                  className="vi_0"
                  onChange={(e) => setAboutImage(e.target.files[0])}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Description</label>
               
                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>
           
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={editAboutDescription}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}

        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={deleteAboutDescription}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default AdminAboutDescription