import React, { useState, useEffect } from 'react';
import "../Styles/Service.css";
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card } from 'react-bootstrap';
import axios from 'axios'

import EnqForm from "./EnqForm";

import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { BsDatabaseDown } from 'react-icons/bs';
import parse from "html-react-parser";
import { useLocation } from 'react-router-dom';
import { BsSearch } from "react-icons/bs";
// import Aos from 'aos';


const Service = () => {

    // Get Selected Category (To get the data based on particular category)

    const location = useLocation();
    const { item } = location.state || {};
    console.log("item", item);

    //MODAL (Functionality for viewing the model)

    const [showMore, setShowMore] = useState(false);
    const [show, setShow] = useState(false);

    const [selectedObj, setSelectedObj] = useState({})
  
    const handleClose = () => setShow(false);

    const handleShow = (data) => {
        setShow(true);
        setSelectedObj(data)
    }


    //GET (Service Category Contents)

    const [FilteCategory, setFilteCategory] = useState([]) //to filter particular category

    const [Category, setCategory] = useState([]) //service category text

    const [serviceCategorylist, setServiceCategoryList] = useState([]) //service catagory contents

    const getServiceList = async () => {

        try {
            let res = await axios.get("https://students-databse.in/api/service/getservicecategorydetails");
            if (res.status === 201) {
                setServiceCategoryList(res.data.data1);
                setCategory(res.data.data1);
                setFilteCategory(res.data.data1);
            }
            if (res.status === 500) {
                alert("Something went wrong..")
            }
        }
        catch (error) {
            console.log(error);

        }
    }

    console.log("serviceCategorylist", serviceCategorylist);


    //GET (service description text)

    const [serviceDesc, setServiceDescription] = useState([])

    const getServiceDescription = async () => {
        try {
            let res = await axios.get("https://students-databse.in/api/service/getdescription");
            if (res.status === 201) {
                setServiceDescription(res.data.data1);
            }
            if (res.status === 500) {
                alert("Something went wrong..")
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getServiceList()
        getServiceDescription()
    }, [])



    //============To filter based on the selected category=================//

    // const [SelectedCategory, setSelectedCategory] = useState("");
    const [SelectedCategory, setSelectedCategory] = useState(item?.category || ""); // Initialize with item category
    const [FilteCategory1, setFilteCategory1] = useState([]);

    // Initialize category from location.state or default to an empty string

    useEffect(() => {
        if (SelectedCategory) {
            const filterdata = FilteCategory?.filter((ele) => ele.category === SelectedCategory);
            setServiceCategoryList(filterdata);
        }
    }, [SelectedCategory, FilteCategory]);


    // Search filter

    const [nochangedata, setNoChangeData] = useState([]);
    const [searchH, setSearchH] = useState("");

    const handleFilterH = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchH(searchTerm); // Update search term state

        if (searchTerm !== "") {
            // Filter based on specific keys (e.g., subtitle, description)
            const filteredData = serviceCategorylist.filter((item) =>
                item.subtitle.toLowerCase().includes(searchTerm) ||
                item.description.toLowerCase().includes(searchTerm)
            );
            setServiceCategoryList(filteredData); // Set filtered data
        } else {
            setServiceCategoryList(nochangedata);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
    
      useEffect(() => {
        // Call the scrollToTop function when the component mounts
        scrollToTop();
      }, []);

    return (
        <div>
            <section className='about-bannerimg'
                style={{
                    backgroundImage: `url("/Assets/banner.jpg")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "200px",
                    width: "100%"
                }}
            >


                <div className="container ">
                    <div className='breadcrumbt'>
                        <div>
                            <h1 className='abtus-heading'>Service</h1>
                        </div>
                        <div className='mbl-responsive-breadcrumbs'>
                            <Breadcrumb>
                                <FontAwesomeIcon icon={faHouse} style={{ fontSize: '14px', marginTop: '0px', color: "rgb(0,105,176)", padding: "5px" }} />
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active className='abtus-small-heading' style={{ color: "white" }}>Service</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                </div>
            </section>


            {/* Service Description */}

            <Container>
                {
                    serviceDesc.map((data) => {
                        return (
                            <div className='mt-5 mb-5 service-desctext' >{parse(`<div>${data?.description}</div>`)}</div>
                        )
                    })
                }
            </Container>

            {/* Service Category */}

 {/* <Col sm={4} className='service-categorycard-mbl'>
                            <Card className="text-center service-category-card" style={{ position: "sticky", top: "150px" }}>
                                <Card.Header className="custom-card-header">
                                    <b className='text-white'>Service List</b>
                                </Card.Header>
                                <Card.Body className='service-list-card-contents'>
                                    <Nav variant="pills" className="flex-column" style={{ width: '300px' }}>
                                        {Array.isArray(Category) && Category.length > 0 ? (
                                            [...new Set(Category.map(item => item.category))].map((uniqueCategory, index) => (
                                                <Nav.Item key={index} className='mt-3'>
                                                    <Nav.Link
                                                        eventKey={index}
                                                        onClick={() => setSelectedCategory(uniqueCategory)}
                                                    >
                                                        {uniqueCategory}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            ))
                                        ) : (
                                            <p>No categories available.</p>
                                        )}
                                    </Nav>
                                </Card.Body>
                            </Card>
                        </Col> */}


            <Container>
                <div className='row mt-5 mb-5'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={0}>
                       


<Col sm={4} className='service-categorycard-mbl'>
            <Card className="text-center service-category-card" style={{ position: "sticky", top: "150px" }}>
                <Card.Header className="custom-card-header">
                    <b className='text-white'>Service List</b>
                </Card.Header>
                <Card.Body className='service-list-card-contents'>
                    <Nav variant="pills" className="flex-column" style={{ width: '300px' }}>
                        {Array.isArray(Category) && Category.length > 0 ? (
                            [...new Set(Category.map(item => item.category))].map((uniqueCategory, index) => (
                                <Nav.Item key={index} className='mt-3'>
                                    <Nav.Link
                                        eventKey={index}
                                        onClick={() => setSelectedCategory(uniqueCategory)}
                                        style={{
                                            textDecoration: SelectedCategory === uniqueCategory ? 'Underline' : 'none',
                                            color: SelectedCategory === uniqueCategory ? 'rgb(0, 105, 176)' : 'black',
                                            fontWeight: SelectedCategory === uniqueCategory ? 'bold' : 'normal'

                                        }}
                                    >
                                        {uniqueCategory}
                                    </Nav.Link>
                                </Nav.Item>
                            ))
                        ) : (
                            <p>No categories available.</p>
                        )}
                    </Nav>
                </Card.Body>
            </Card>
        </Col>

                        <Col sm={8}>
                            <div className='service-mainbox'>
                                <p className='cardd-mainheading text-white'>{SelectedCategory}</p>
                                <div className="servicedt justify-content-center">
                                    <div className="input-group bbb">
                                        <span className="input-group-text"><BsSearch /></span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Search..."
                                            onChange={handleFilterH}
                                        />
                                    </div>
                                </div>
                                {serviceCategorylist.map((data) => (
                                    <div className='row service-contents-card mt-3 mb-3'>
                                        <div className='col-md-4 mb-3'>
                                            <a href={`https://students-databse.in/ServiceCategoryDetails/${data?.image}`} target='_blank'>
                                                <img
                                                    className="service-largeimg"
                                                    src={`https://students-databse.in/ServiceCategoryDetails/${data?.image}`}
                                                    alt="pic"
                                                />
                                            </a>
                                        </div>
                                        <div className='col-md-8'>
                                            <h5 className="card-title mt-2 mb-2 text-white">{data?.subtitle}</h5>
                                            <p className="card-text mb-3 text-white serviceDesc">
                                                {parse(`<div>${data?.description}</div>`)}
                                            </p>
                                            <EnqForm productid={data?._id} />
                                            <button className="btn btn-secondary service-view-more" onClick={() => handleShow(data)}>
                                                View More
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Col>
                    </Tab.Container>
                </div>
            </Container>


            {/* Modal for Service Description*/}


            <Modal show={show} onHide={handleClose}>

                <Modal.Header closeButton>
                    <Modal.Title>{selectedObj?.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <h6>{selectedObj?.subtitle}</h6>
                    <br />
                    <p>
                        {parse(`<div>${selectedObj?.description}</div>`)}
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>

            </Modal>
        </div>
    );
};


export default Service;
