import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image, Form } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import axios from "axios";
import parse from "html-react-parser";


const AdminProjectCategoryContents = () => {

     // Ck Editor Code
  const [Desc1, setDesc1] = useState("")

  const handleChange1 = (e, editor) => {
    const data = editor.getData();
    setDesc1(data)
  }

  const handleChange2 = (e, editor) => {
    const data = editor.getData();
    setDesc2(data)
  }

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


  //Frontend Integration

  const [ProjecCategoryText, setProjecCategoryText] = useState("");
  const [ProjectDescription, setProjectDescription] = useState("");
  const [ProjectImage, setProjectImage] = useState("");

  const [ProjectContent1Id, setProjectContent1Id] = useState({})

  //const [BannerSubtitle, setBannerSubtitle] = useState("");
  
  // const [selectedOption, setSelectedOption] = useState(AboutContent1Id?.category);

  //POST

  const createProjectCategoryDetails = async () => {
    try {
      const config = {
        url: "/createprojectcategorydetails",
        method: "post",
        baseURL: "https://students-databse.in/api/project",
        headers: { "content-type": "multipart/form-data" },
        data: {
          projectcategory:ProjecCategoryText,
          projectdescription: Desc2,
          projectimage: ProjectImage,
         
        },
      };
      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        getProjectCategoryDetails()

        setProjecCategoryText("");
        setDesc2("");
        setProjectImage("");

        //setBannerSubtitle('');
       // setDesc2("");

        // setBannerVideo("");
        // setDesc1("");
        
      }
      if(res.status===400){
        alert("Failed to save Service Details")
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  
//GET

const [ProjectCategoryContents, setProjectCategoryContents] = useState([]);

const getProjectCategoryDetails = async () => {
  try {
    let res = await axios.get("https://students-databse.in/api/project/getprojectcategorydetails");
    if (res.status === 201) {
      setProjectCategoryContents(res.data.data1);     
    }
  } catch (error) {
    console.log(error,"data some data");
  }
};


  //EDIT

//For Update

const [ProjecCategoryText1, setProjecCategoryText1] = useState(ProjectContent1Id?.projectcategory);
//const [ProjectDescription1, setProjectDescription1] = useState(ProjectContent1Id?.projectdescription);
const [Desc2, setDesc2] = useState(ProjectContent1Id?.projectdescription)
const [ProjectImage1, setProjectImage1] = useState(ProjectContent1Id?.projectimage);

//const [BannerSubtitle1, setBannerSubtitle1] = useState(AboutContent1Id?.subtitle);
//const [Desc2, setDesc2] = useState(AboutContent1Id?.description)


  const editProjectCategoryDetails = async () => {
    try {
      const config = {
        url: "/updateprojectcategorydetails/" + ProjectContent1Id?._id,
        method: "put",
        baseURL: "https://students-databse.in/api/project",
        headers: { "content-type": "multipart/form-data" },
        data: {

          projectcategory:ProjecCategoryText1,
          projectdescription: Desc2,
          projectimage: ProjectImage1,

        //  subtitle: BannerSubtitle1,
        //  description: Desc2,
        },
      };

      let res = await axios(config);
      if (res.status === 201) {
        alert(res.data.success);
        handleClose4()

        getProjectCategoryDetails()

        setProjecCategoryText1("");
        setDesc2("");
        setProjectImage1("");

      //  setBannerSubtitle1("");
      //  setDesc2("");
      }
    }
    catch (error) {
      alert(error.response.data.error);
    }
  }

  //DELETE

  const deleteProjectCategoryDetails= async () => {
    try {
      let res = await axios.delete(`https://students-databse.in/api/project/deleteprojectcategorydetails/${ProjectContent1Id?._id}`);
      if (res.status === 201) {
        alert(res.data.message);
        getProjectCategoryDetails()
        handleClose5()
      }
    } catch (error) {
      alert(error.response.data.message)
    }
  };

  // useEffect(() => {
  //   getAdminContent1();
  //   getAboutCategory()
  // }, []);

useEffect(()=>{
  // getAboutCategory()
  getProjectCategoryDetails()
  getProjectCategoryText()
},[])


//GET (project category text)


const [ProjectCategoryText, setProjectCategoryText] = useState([]);

const getProjectCategoryText = async () => {
  try {
    let res = await axios.get("https://students-databse.in/api/project/getprojectcategory");
    if (res.status === 201) {
      setProjectCategoryText(res.data.data1);
    }
  } catch (error) {
    alert(error.response.data.error); 
  }
};

//GET (service category text)

// const [categorylist,setCategoryList]=useState([])

// const getServiceCategory=async()=>{
//   try{
//     let res = await axios.get("https://students-databse.in/api/service/getservicecategory");
//     if (res.status === 201) {
//       setCategoryList(res.data.data1);
//     }
//     if (res.status === 400) {
//       alert("Failed to fetch Category")
//     }
//     if (res.status === 500) {
//       alert("Something went wrong")
//     }
//   }
//   catch(error){
//     console.log(error)
//   }
// }

// console.log("AboutContent1Id",AboutContent1Id);


  return (
    <div>
    <div className="customerhead p-2">

        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Choose Product/Service List</h2>
          <div className="d-flex gap-3">
            <button className="admin-add-btn" onClick={handleShow3}>
              Add
            </button>
          </div>
        </div>

  <div className="mb-3">
    <Table
      responsive
      bordered
      style={{ width: "-webkit-fill-available" }}
    >
      <thead>
        <tr>
          <th>Sl.No</th>
          <th>Project Category</th>

          {/* <th>Title</th> */}
          <th>Image</th>
          {/* <th>Product</th> */}
          <th>Description</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody>

        {ProjectCategoryContents?.map((item, i) => { 
           return (

            <tr>
              <td>{i+1}</td>
            
              <td style={{ paddingTop: "10px" }}>{item?.projectcategory}</td>
           
              <td>
                <img
                  src={`https://students-databse.in/ProjectCategoryDetails/${item?.projectimage}`}
                  alt="pic"
                  style={{ width: "75px", height: "75px" }}
                />{" "}             
              </td>

              
              <td style={{ paddingTop: "10px" }}>
                {parse(`<div>${item?.projectdescription}</div/>`)}               
              </td>

              <td>
                {" "}
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                  }}
                >

                  <div>
                    <BiSolidEdit
                      className=""
                      style={{ cursor: "pointer", fontSize: "20px", color: "rgb(101, 177, 69)" }}
                      onClick={() => {
                        handleShow4()
                        setProjectContent1Id(item)
                      }
                      }

                    />{" "}
                  </div>

                  <div>
                    <AiFillDelete
                      className=""
                      style={{ cursor: "pointer", fontSize: "20px", color: "rgb(25, 107, 156)" }}
                      onClick={() => {
                        handleShow5();
                       setProjectContent1Id(item)
                       
                      }}
                    />
                  </div>
                </div>
              </td>
            </tr>

          );
        })} 

      </tbody>
    </Table>
  </div>


  {/* Add Package modal for Slider */}
  <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
    <Modal.Header
      closeButton
    >
      <Modal.Title>Add Service/Product</Modal.Title>
    </Modal.Header>
    <Modal.Body>

      <div className="row">
        <div className="do-sear mt-2">
          <label>Category</label>

          <Form.Select
         onChange={(e)=>setProjecCategoryText(e.target.value)}
          >
            <option>select</option>

             {ProjectCategoryText?.map((item)=>{
              return( 

                 <option value={item?.projecttext}>{item?.projecttext}</option> 
          
             )
            })} 
          </Form.Select>

        </div>
      </div>

      {/* <div className="row">
        <div className="do-sear mt-2">
          <label>Add Category Title</label>
          <input
            type="text"
            className="vi_0"
            placeholder="Enter Category Title"
            value={BannerTitle}
            onChange={(e) => setBannerTitle(e.target.value)}
          />

        </div>
      </div> */}


      <div className="row">
        <div className="do-sear mt-2">
          <label>Image</label>
          <input
            type="file"
            name=""
            accept="image/*"
            id=""
            className="vi_0"
            onChange={(e) => setProjectImage(e.target.files[0])}
          />
        </div>
      </div>

      <div className="row">
        <div className="do-sear mt-2">

          <label>Add Description</label>

          <CKEditor
            editor={ClassicEditor}
           onChange={handleChange2}
          />

        </div>
      </div>

    </Modal.Body>

    <Modal.Footer>
      <div className="d-flex">
        <Button
          className="mx-2 modal-close-btn"
          variant=""
          onClick={handleClose3}
        >
          Close
        </Button>
        <Button
          className="mx-2 modal-add-btn"
          variant=""
          onClick={createProjectCategoryDetails}
        >
          Add
        </Button>
      </div>
    </Modal.Footer>
  </Modal>

  {/* Edit Package modal for Slider */}

  <Modal
    show={show4}
    onHide={handleClose4}
    backdrop="static"
    keyboard={false}
    style={{ zIndex: "99999" }}
  >
    <Modal.Header
      closeButton
    >
      <Modal.Title style={{ color: "black" }}>Edit Category</Modal.Title>
    </Modal.Header>

    <Modal.Body>

      {/* <div className="row">
        <div className="do-sear mt-2">
          <label>Edit Category Title</label>
          <input
            type="text"
            className="vi_0"
            placeholder="Enter Banner Title"
            onChange={(e) => setBannerTitle(e.target.value)}
          />
        </div>
      </div> */}

      <div className="row">
        <div className="do-sear mt-2">
          <label>Category</label>

          <Form.Select
          onChange={(e)=>setProjecCategoryText1(e.target.value)}
          value={ProjecCategoryText1}
          >
    
          {ProjectCategoryText?.map((item)=>{
              return( <>{
                item?.projectcategory
              }
                 <option value={item?.projecttext}>{item?.projecttext}</option> 
                 </>

          )
            })} 

          </Form.Select>

        </div>
      </div>

{/* 
      <div className="row">
        <div className="do-sear mt-2">
          <label>Edit Category Title</label>
          <input
            type="text"
            className="vi_0"
            placeholder="Enter Category Title"
            onChange={(e) => setBannerTitle1(e.target.value)}
            value={BannerTitle1}
          />

        </div>
      </div> */}

      
      <div className="row">
        <div className="do-sear mt-2">
          <label>Edit Image</label>
          <input
            type="file"
            name=""
            id=""
            className="vi_0"
            onChange={(e) => setProjectImage1(e.target.files[0])}
            // value={ProjectImage1}
          />
        </div>
      </div>


      {/* <div className="row">
        <div className="do-sear mt-2">
          <label>Edit Product</label>
          <input
            type="text"
            className="vi_0"
            placeholder="Enter Category Title"
            onChange={(e) => setBannerSubtitle1(e.target.value)}
           value={BannerSubtitle1}
          />

        </div>
      </div> */}


      <div className="row">
        <div className="do-sear mt-2">
          <label>Edit Description</label>
          <CKEditor
            editor={ClassicEditor}
           onChange={handleChange2}
           data={Desc2}
          />
        </div>
      </div>

    </Modal.Body>

    <Modal.Footer>
      <Button
        variant=""
        className="modal-close-btn"
        onClick={handleClose4}
      >
        Close
      </Button>
      <Button variant="" className="modal-add-btn"
       onClick={editProjectCategoryDetails}
      >
        Update
      </Button>
    </Modal.Footer>
  </Modal>


  {/*Delete Package modal for Slider */}

  <Modal
    show={show5}
    onHide={handleClose5}
    backdrop="static"
    keyboard={false}
    style={{ zIndex: "99999" }}
  >
    <Modal.Header
      closeButton
    >
      <Modal.Title>Warning</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-md-12">
          <p className="fs-4" style={{ color: "red" }}>
            Are you sure?
            <br /> you want to delete this data?
          </p>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button
        variant=""
        className="modal-close-btn"
        onClick={handleClose5}
      >
        Close
      </Button>
      <Button
        variant=""
        className="modal-add-btn"
        onClick={deleteProjectCategoryDetails}
      >
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
</div>
</div>
  )
}

export default AdminProjectCategoryContents