import React, { useState, useEffect } from 'react';
import "../Styles/FooterMain.css"
import { Container } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
import axios from 'axios'
import parse from "html-react-parser";


const FooterMain = () => {

  //GET (About Description)

  const [AboutDescription, setAboutDescription] = useState([]);
 
  const getAboutDescription = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/aboutdescription/getaboutdescription");
      if (res.status === 201) {
        setAboutDescription(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  console.log("adsadasdasd", AboutDescription);

  useEffect(() => {
    getAboutDescription();
   
  }, []);

//GET (Contact Address)

  const [Contactdetails,setContactdetails]=useState([])

  const getContactDetails = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/contact/getcontactdetails");
      if (res.status === 201) {
          setContactdetails(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };

  useEffect(() => {
    getContactDetails()
  
  }, [])

  return (
    <>

       <div className="container-fluid footer-main-div-gg mt-3">

        <div className="row">

          <div className="col-md-5">
            <div className="footermain footer1">
                <div className="footer-header">
                  <h3 className="mt-3 mb-3 text-start">About Us</h3>
                </div>

                {/* {AboutDescription?.map((item, i) => {
               return (

                  <p className="text-wrap text-wrap-height text-white AboutDescription">
                       <td style={{ paddingTop: "10px" }}>{parse(item?.description)}</td>
                  </p>
               );
              })} */}


                {AboutDescription?.map((item, i) => {
                    return (
                        <div className="text-wrap text-wrap-height text-white AboutDescription">
                            <a href='/about' className='footer-abt-desc'><p style={{ paddingTop: "10px" }}>{parse(item?.description)}</p></a>
                        </div>
                    );
                })}


            </div>
          </div>

          <div className="col-md-3">
            <div className="footermain footer2">
              <div className="footer-header">
                <h3 className="mt-3 mb-3 text-start">Quick Links</h3>

                <ul className="list-unstyled">
                  <div className='d-flex gap-2'>
                     <FaLongArrowAltRight />
                    <a href="/" className="quicklinks">
                      <li className="quicklinkmain text-left text-white text-start">HOME</li>
                    </a>
                  </div>

                  <div className='d-flex gap-2'>
                      <FaLongArrowAltRight />
                    <a href="/about" className="quicklinks">
                      <li className="quicklinkmain text-left text-white text-start">ABOUT</li>
                    </a>
                  </div>

                  <div className='d-flex gap-2'>
                     <FaLongArrowAltRight />
                    <a href="/service" className="quicklinks">
                      <li className="quicklinkmain text-left text-white text-start">SALES & SERVICES</li>
                    </a>
                  </div>

                  <div className='d-flex gap-2'>
                    <FaLongArrowAltRight />
                    <a href="/project" className="quicklinks">
                      <li className="quicklinkmain text-left text-white text-start">PROJECT</li>
                    </a>
                  </div>

                  <div className='d-flex gap-2'>
                      <FaLongArrowAltRight />
                    <a href="/contact" className="quicklinks">
                      <li className="quicklinkmain text-left text-white text-start">CONTACT</li>
                    </a>
                  </div>

                </ul>

              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="footermain footer3">
              <div className="footer-header">
                <h3 className="mt-3 mb-3 text-start">Contact Us</h3>

                {
                   Contactdetails.map((item, i)=>{
                    return(
                <div>
                
                      <div className="fs-6 text-white contact-description">
                          <p style={{ paddingTop: "10px" }}>{parse(item?.address)}</p>
                          <p style={{ paddingTop: "10px" }}>{parse(item?.altaddress)}</p>
                      </div>
             
                </div>

              )
              })
              }

              </div>
            </div>
          </div>

      </div>
    </div> 
     

    </>
  );
};

export default FooterMain;