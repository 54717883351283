import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import offcanvasNavbar from 'react-bootstrap/Offcanvas'
import offcanvasNavbarLabel from 'react-bootstrap/Offcanvas'

//import { Offcanvas } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
// import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";

// import EnqForm from "../EnqForm";
import EnqForm from "./EnqForm";
import GeneralEnqForm from "./GeneralEnqForm";

const NavBarMain = () => {

    return (

        <div
            className="headers"
            style={{
                position: "sticky",
                top: "0px",
                zIndex: "9999999",
                backgroundColor: "white",
            }}
        >
            
            {["xl"].map((expand) => (
                <Navbar
                    key={expand}
                    expand={expand}
                    style={{ padding: "0px", background: "white"}}
                >
                    <Container fluid style={{ padding: "5px 20px" }}>
                        <div className="d-flex gap-2 bg-logo-color" >
                            <a href="/" className="tail-text">
                                <img src=".../../Assets/Logo.jpg" alt="Logo" className="logoNavMain" />
                            </a>
                        </div>

                        <Navbar.Toggle
                            aria-controls={offcanvasNavbar - expand - `${expand}`}
                        />

                        <Navbar.Offcanvas
                            id={offcanvasNavbar - expand - `${expand}`}
                            aria-labelledby={offcanvasNavbarLabel - expand - `${expand}`}
                            placement="end"
                        >

                        <Offcanvas.Header closeButton></Offcanvas.Header>
                            <Offcanvas.Body>

                                <Nav
                                    className="justify-content-end flex-grow-1 pe-3"
                                    style={{ alignItems: "center" }}
                                >

                                    <Nav.Link href="/" className="tail-text-ss md-2 ">
                                        <div className="underline-hover">HOME</div>
                                    </Nav.Link>

                                    <Nav.Link href="/about" className="tail-text-ss">                                      
                                        <div className="underline-hover">ABOUT</div>
                                    </Nav.Link>

                                    {/* <Nav.Link href="/products" className="tail-text-ss">
                                    <div className="underline-hover">PRODUCTS</div>                                        
                                    </Nav.Link> */}
                                   
                                    <Nav.Link href="/service" className="tail-text-ss">
                                        <div className="underline-hover">SALES & SERVICES</div>                                       
                                    </Nav.Link>

                                    {/* <Nav.Link href="/project" className="tail-text-ss">
                                        <div className="underline-hover">PRODUCT</div>                                       
                                    </Nav.Link>   */}

                                    <Nav.Link href="/project" className="tail-text-ss">
                                        <div className="underline-hover">PROJECT</div>                                       
                                    </Nav.Link> 

                                    <Nav.Link href="/contact" className="tail-text-ss">
                                         <div className="underline-hover">CONTACT</div>
                                     </Nav.Link> 
                                    
                                     <GeneralEnqForm/>

                                    {/* <Form inline>
                                        <Row>
                                            <Col xs="auto mt-1">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Search"
                                                   style={{width:'100%'}}
                                                    className=" mr-sm-2"
                                                />
                                            </Col>
                                            <Col xs="auto mt-1 ">                                        
                                                <Link to='/welcome' className=""><Button>Login</Button></Link>
                                            </Col>
                                        </Row>
                                    </Form> */}

                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            ))}
        </div>
    )
}

export default NavBarMain