import React, { useEffect, useState } from "react";
import { MdOutlineMailOutline } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import "../Styles/NavBar.css";
// import EnqForm from "../EnqForm";
import EnqForm from "./EnqForm";
import axios from "axios";

const NavBarTop = () => {

  //GET

const [Text, setText] = useState([]);

const getScrollingText = async () => {
  try {
    let res = await axios.get("https://students-databse.in/api/scrollingtext/getscrollingtext");
    if (res.status === 201) {
      setText(res.data.data1);
    }
  } catch (error) {
    alert(error.response.data.error); 
  }
};

useEffect(() => {
  getScrollingText();
}, []);


  return (
    <div className="d-flex justify-content-between align-items-center pt-1 pb-1 mobileres-nav">
    
    {Text?.map((item) => {
              return (

    <div className="scrolling-text">
      <span className="scrolling-text-navtop">{item?.text}</span>
    </div>

        );
        })}

  </div> 
  );
};

export default NavBarTop;


//Previous Code

{/* <div className="d-flex  justify-content-between align-items-center pt-1 pb-1 mobileres-nav   ">
      
<div className="divleft  ps-4">
  <div className="position-relative ">
    <span>
      <MdOutlineMailOutline className="top-nav-icon" />
    </span>
    <p className="topnavposi">ekadantha@gmail.com</p>
  </div>

  <div className="position-relative">
    <span>
      <FaPhoneAlt className="top-nav-icon" />
    </span>
    <p className="topnavposi">+919876543266</p>
  </div>

</div>

<div className="divcenter text-center ">
  <div>
  <p className=""> Monday - Saturday </p>
  <p className=""> 10.00 AM - 6.00 PM </p>
  </div>
</div>

<div className="divright pe-4 ">
<EnqForm/>
</div> 

</div>
 */}

//Megha

//  <div className="d-flex  justify-content-between align-items-center pt-1 pb-1 mobileres-nav   ">   
//  <p>Scrolling text</p>
// </div>    