import React, { useState, useEffect } from 'react'
import "../Styles/About.css"
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card, Row, Col } from "react-bootstrap";

import OwlCarousel from 'react-owl-carousel';
import Aos from 'aos';

import axios from "axios";

import parse from "html-react-parser";

const About = () => {

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 4,

      }
    },
  };

  // useEffect(() => {
  //   Aos.init();
  //   window.scroll(0, 0)
  // })


  //GET (About Description)

  const [AboutDescription, setAboutDescription] = useState([]);
  const [missionvision, setMissionVision] = useState([])
  const [ourpartners, setOurPartners] = useState([])

  const getAboutDescription = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/aboutdescription/getaboutdescription");
      if (res.status === 201) {
        setAboutDescription(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };
  console.log("adsadasdasd", AboutDescription);

  useEffect(() => {
    getAboutDescription();
    getMissionAndVision()
    
    getAboutBanner()
  }, []);
  useEffect(()=>{
    if(ourpartners){
      getOurPartners()
    }
  
  },[ourpartners])


  // GET (Mission and Vision)

  const getMissionAndVision = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/about/getmissionvision");
      if (res.status === 201) {
        setMissionVision(res.data.newMissionVision);
      }
      if (res.status === 400) {
        alert("Failed to fetch Mission And Vision")
      }
    }
    catch (error) {
      console.log(error)
    }
  }


  // GET (Partner Image) 

  const getOurPartners = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/about/getaboutpartners");
      if (res.status === 201) {
        setOurPartners(res.data.data1);
      }
      if (res.status === 500) {
        alert("Something went wrong..")
      }
    }
    catch (error) {
      console.log(error);

    }
  }

  // GET (About Banner) 

  const [banner, setBanner] = useState([])
  
  const getAboutBanner = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/aboutbanner/getaboutbanner");
      if (res.status === 201) {
        setBanner(res.data.data1);
      }
      else if (res.status === 500) {
        alert('Something went wrong..!')
      }
    }
    catch (error) {
      console.log(error);

    }
  }

  return (
    <div className='about-maindiv'>

      {/*About Banner */}

      <section className='about-bannerimg'
        style={{
          backgroundImage: `url("/Assets/banner.jpg")`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          height: "200px",
          width: "100%"
        }}
      >

        <div className="container ">
          <div className='breadcrumbt'>
            <div>
              <h1 className='abtus-heading'>About</h1>
            </div>
            <div className='mbl-responsive-breadcrumbs'>
              <Breadcrumb >
                <FontAwesomeIcon icon={faHouse} className='home-icon' style={{ fontSize: '14px', marginTop: '0px', color: "rgb(0,105,176)", padding: "5px" }} />
                <Breadcrumb.Item href="/" className='home-small-heading' >Home</Breadcrumb.Item>
                <Breadcrumb.Item active className='abtus-small-heading' style={{ color: "white" }}>About</Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>

        </div>
      </section>


      <Container>

        {AboutDescription?.map((item, i) => {
          return (<>

            {i % 2 === 0 ? (
              <div className='row mt-5 mb-3 about-box1'>
                <div className='col-md-6' data-aos="flip-left" data-aos-duration="3000">
                  <img className='abt-content-img1'
                    src={`https://students-databse.in/AboutDescription/${item?.image}`}
                    alt="pic"

                  />{" "}
                </div>

                <div className='col-md-6' data-aos="fade-down" data-aos-duration="3000">

                  {typeof item?.description === 'string' ? parse(item.description) : null}
                </div>
              </div>
            ) : (<div className='row mt-5 mb-3 about-box2'>
              <div className='col-md-6' data-aos="fade-up" data-aos-duration="3000">
                {typeof item?.description === 'string' ? parse(item.description) : null}
              </div>

              <div className='col-md-6' data-aos="flip-right" data-aos-duration="3000">
                <img src={`https://students-databse.in/AboutDescription/${item?.image}`} alt="" className='abt-content-img2' />
              </div>
            </div>)}

          </>
          );
        })}


      </Container>

      {/* Mission and Vision */}

      <p className='text-center mission-vision-heading'>Mission and Vision</p>


      <Container>

        <Row className='mt-5 mb-3 '>
          {
            missionvision?.map((data) => {
              return (
                <>
                  <Col md={6} className='offset-3 mission-vision-container' data-aos="zoom-in" data-aos-duration="1200">
                    <div className='main-heading mb-3'>

                      <Card className="text-center">
                        <Card.Header className="custom-card-header">
                          <b className='mission-vision text-white'>{data?.missiontitle}</b>
                        </Card.Header>
                        <Card.Body>
                          <Card.Text>
                            {/* {data?.description} */}
                            {parse(`<div>${data?.description}</div>`)}
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </Col>

                </>
              )
            })
          }

        </Row>
      </Container>


      {/* Our Partners */}

      <h1 className='ourpartner-heading mt-4 mb-3 text-center'>Our Partners</h1>

       <div className='ourpartners-box'>

        <Container >
          <div className='row mt-2 mb-3'>
            <div className='col-md-12'>
              <OwlCarousel className='owl-theme' loop={false} margin={10} {...options}>
      
                {
                  ourpartners.map((data) => {
                    return <div className='item '>
                      <img class="" src={`https://students-databse.in/AboutPartners/${data?.partnerimage}`} alt="Card image cap" className='partener-icons' />
                      </div>

                  })
                }


          {/* 
              {
                ourpartners?.filter((ele) => ele.category === uniqueCategory)?.map((uniqueCategory) => {
                    return <div className='item '>
                      <img class="" src={`https://students-databse.in/AboutPartners/${uniqueCategory}`} alt="Card image cap" className='partener-icons' />
                      </div>

                  })
                } */}

              </OwlCarousel>
            </div>
          </div>
        </Container>

      </div> 
 

    </div>
  )
}

export default About