import React, { useState, useEffect } from 'react';
import "../Styles/Contact.css"

import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { faHouse } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Card } from 'react-bootstrap';

import axios from 'axios'

import Aos from 'aos';
import parse from "html-react-parser";

const Contact = () => {

    useEffect(() =>{
        Aos.init();
        // window.scroll(0,0)
      })

      useEffect(() => {
        getContactDetails()
        getContactDescription()
      }, [])


//GET(Contact Details)
      
const [Contactdetails,setContactdetails]=useState([])

const getContactDetails = async () => {
  try {
    let res = await axios.get("https://students-databse.in/api/contact/getcontactdetails");
    if (res.status === 201) {
        setContactdetails(res.data.data1);
    }
  } catch (error) {
    alert(error.response.data.error); 
  }
};

//GET (Contact Description)

const [contactDescription,setContactDescription]=useState([])

const getContactDescription=async()=>{
    try{
        let res = await axios.get("https://students-databse.in/api/contact/getcontactdescription");
        if (res.status === 201) {
            setContactDescription(res.data.data1);
        }
        else if(res.status===400){
            alert("Failed to fetch the contact details")
        }
    }
    catch(error){
        alert("Internal server error")
    }
    }

    //POST (Contact General Enquiry Details)

    const [name,setName]=useState('')
    const [email,setEmail]=useState('')
    const [phone,setPhone]=useState('')
    const [address,setAddress]=useState('')
    
    const handleData=async()=>{

    try{
        if(!name){
            alert("Please Enter Your Name.")
            return;
        }
        if(!email){
            alert("Please Enter Your Email Id.")
            return;
        }
        if(!phone){
            alert("Please Enter Your Phone Number.")
            return;
        }
        if(!address){
            alert("Please Enter Your Address.")
            return;
        }
    
        let res = await axios.post("https://students-databse.in/api/enquiryform/createenquiryform",{name,phone,email,address});
        if (res.status === 200) {
            alert('Thank you for reaching us.')
            setName('')
            setEmail('')
            setPhone('')
            setAddress('')
        }
        else if(res.status===400){
            alert('Can not add, something went wrong')
        }
    }
    catch(error){
        console.log(error);
        
    }
}

  return (
    <div>
   
    {/* Contact Banner */}

    <section className='about-bannerimg'
                style={{
                    backgroundImage: `url("/Assets/banner.jpg")`,
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    height: "200px",
                    width: "100%"
                }}
            >
                <div className="container ">

                    <div className='breadcrumbt'>
                        <div>
                            <h1 className='abtus-heading'>Contact</h1>             
                        </div>
                        <div className='mbl-responsive-breadcrumbs'>
                            <Breadcrumb>
                                <FontAwesomeIcon icon={faHouse} style={{ fontSize: '14px', marginTop: '0px', color: "rgb(0,105,176)", padding:"5px" }} />
                                <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                                <Breadcrumb.Item active className='abtus-small-heading' style={{color:"white"}}>Contact</Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    
                </div>
    </section>

            <section>
                {/* <img src="../Assets/contactus1.png" alt="" className='setion-img' /> */}
               
                <Container >
                    <div class='row mt-5 mb-3'>
                        
                        <div class="col-md-6" >
                            {
                                Contactdetails.map((item, i)=>{
                                    return(
                                        <>
                             <div class="contact-bx" >
                                <img src="../Assets/locations.png" alt="Location" />
                                <h3 class="sect-title-3">Address 1</h3>
                                <p className='text-justify mt-2 content-text'>{item?.address}</p>
                            </div>
                       
                            
                            <div class="contact-bx" >
                                <img src="../Assets/locations.png" alt="Location" />
                                <h3 class="sect-title-3">Address 2</h3>
                                <p className='text-justify mt-2 content-text'>{item?.altaddress}</p>
                            </div>

                      
                            <div class="contact-bx" >
                                <img src="../Assets/contacticon.png" alt="Location" />
                                <h3 class="sect-title-3">Contact Number</h3>
                                <p className='text-left mt-2 content-text'>{item?.phone}</p>                              
                             </div>

                      
                            <div class="contact-bx">
                                <img src="../Assets/email.png" alt="Location" />
                                <h3 class="sect-title-3">Email</h3>
                                <p className='mt-2'>{item?.email}</p>
                            </div>
                                        </>
                                    )
                                })
                            }
                          

                        </div>
                        

                        <div className='col-md-6 mt-5' data-aos="fade-up" data-aos-duration="1000">
                        {
                            contactDescription.map((data)=>{
                            return(

                                <td>{parse(data?.contactdescription)}</td>
                            )
    
                            })
                        }


                        <div className='form-img' data-aos="zoom-in" data-aos-duration="2000">
                            <img src='../Assets/contact1.jpg' alt="Contact" className='img-fluig mt-3' />
                        </div>

                        {/* <img
                            className="service-largeimg"
                            src={`https://students-databse.in/ContactImage/${data?.image}`}
                            alt="pic"
                        /> */}


                        </div>

                    </div>
                </Container>
            </section> 



             {/* <Container>
                <div className='row' id='contct-img'>
                    <div className='col-md-6 col-12'>

                        <form className='contact-form' data-aos="flip-left" 
                            data-aos-easing="ease-out-cubic"
                            data-aos-duration="2000">

                            <div className='form-bg'>
                                <h1 style={{ color: "white" }}>Reach-Us</h1>

                                <div className='row'>

                                    <div className='col-12'>
                                        <div className='single-form'>
                                            <input type="text" className='form-control' placeholder='Name...' value={name} onChange={(e)=>setName(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className='col-12'>
                                        <div className='single-form'>
                                            <input type="email" className='form-control' placeholder='Email...' value={email} onChange={(e)=>setEmail(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className='col-12'>
                                        <div className='single-form'>
                                            <input type="tel" className='form-control' placeholder='Phone Number...' value={phone} onChange={(e)=>setPhone(e.target.value)}/>
                                        </div>
                                    </div>

                                    <div className='col-12'>
                                        <div className='single-form'>
                                            <input type="text" className='form-control' placeholder='Location...' value={address} onChange={(e)=>setAddress(e.target.value)}/>
                                        </div>
                                    </div>


                                    <div className='col-12'>
                                        
                                        <div>
                                            <button type='button' class="upgrade  btn btn-primary btn block mt-3" onClick={handleData}>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div className='col-md-6 col-12 mt-3 mb-3'>
                        <div className='form-img' data-aos="zoom-in" data-aos-duration="2000">
                            <img src='../Assets/contact1.jpg' alt="Contact" className='img-fluig' />
                        </div>
                    </div>
                    
                </div>
            </Container>          */}
            
    </div>
  )
}

export default Contact