import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


const AdminHomeFeature = () => {

    // Ck Editor Code
  const [Desc, setDesc] = useState("");

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

//Frontend Integration

   //POST

   const createHomeFeature = async () => {
    try {
      const config = {
        url: "/createfeature",
        method: "post",
        baseURL: "https://students-databse.in/api/homefeature",
        headers: { "content-type": "application/json" },
        data: {         
          description: Desc,
        },
      };

      let res = await axios(config);
      if (res.status === 200) {
        alert(res.data.status);
        handleClose3();
        setDesc("");
      }
    } catch (error) {
      alert(error.response.data.error);
    }
  };


  //GET

  const [HomeFeature, setHomeFeature] = useState([]);

  const getHomeFeature = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/homefeature/getfeature");
      if (res.status === 201) {
        setHomeFeature(res.data.data1);
      }
    } catch (error) {
      alert(error.response.data.error); 
    }
  };


  //UPDATE
  
const [HomeFeatureId, setHomeFeatureId] = useState({})

  const editHomeFeature = async()=>{
    try {
    const config = {
      url: "/updatefeature/"+HomeFeatureId?._id,
      method: "put",
      baseURL: "https://students-databse.in/api/homefeature",
      headers: { "content-type": "application/json" },
      data: {
        description: Desc,
      },
    };

    let res = await axios(config);
    if (res.status === 201) {
      alert(res.data.sucess);
      handleClose4()
      getHomeFeature();
      setDesc("");
    }
  }
   catch (error) {
    alert(error.response.data.error);
  }
  }

  //DELETE

  const deleteHomeFeature = async () => {
    try {
      let res = await axios.delete(`https://students-databse.in/api/homefeature/deletefeature/${HomeFeatureId?._id}`);
      if (res.status === 201) {
        alert(res.data.message);
        getHomeFeature();
        handleClose5()
      }
    } catch (error) {
      alert(error.response.data.message)
    }
  };

  useEffect(() => {
    getHomeFeature();
  }, []);
 

  return (
    <div>
        <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Home Feature</h2>
          <div className="d-flex gap-3">

          {HomeFeature?.length ? (""):(
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Home Feature
            </button>
             )}
             
          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                {/* <th>Sl.No</th> */}
             
                <th>Description</th>
               
                <th>Action</th>
              </tr>
            </thead>

            <tbody>

            {HomeFeature?.map((item, i) => {
                return (
             
                  <tr>
                    {/* <td></td> */}
                    
                    <td style={{ paddingTop: "10px" }}>{parse(item?.description)}</td>
                      
                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>

                          <BiSolidEdit
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(9,122,215)",
                            }}
                            onClick={() =>{
                              handleShow4()
                              setHomeFeatureId(item)
                            }
                               
                              }
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(172,172,172)",
                            }}
                            onClick={() => {
                              handleShow5();

                              setHomeFeatureId(item)
                              // setDatas(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                );
              })}
            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}

        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Home Feature</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description</label>
               
                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                onClick={createHomeFeature}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}

        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Home Feature
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
          
            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Description</label>
                <CKEditor editor={ClassicEditor} onChange={handleChange} />
                
              </div>
            </div>

           
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
               onClick={editHomeFeature}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delete Package modal for Slider */}

        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
             onClick={deleteHomeFeature}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default AdminHomeFeature