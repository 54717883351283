import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AdminAboutMissionVision = () => {

  // Ck Editor Code
  const [Desc, setDesc] = useState("");
  const [missiontitle, setMissionTitle] = useState("")

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setDesc(data);
  };

  const handleChange2 = (e, editor) => {

    const data = editor.getData();
    setEditableDesc(data);
  };

  const [editObj, setEditObj] = useState({})
  const [editedTitle, setEditedTitle] = useState("")
  const [editableDesc, setEditableDesc] = useState("")

  useEffect(() => {
    setEditedTitle(editObj?.missiontitle)
    setEditableDesc(editObj?.description)
  }, [editObj])



  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);

  //Frontend Integration

  //GET

  const [missionvision, setMissionVision] = useState([])
  const getMissionAndVision = async () => {
    try {
      let res = await axios.get("https://students-databse.in/api/about/getmissionvision");
      if (res.status === 201) {
        setMissionVision(res.data.newMissionVision);
      }
      if (res.status === 400) {
        alert("Failed to fetch Mission And Vision")
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  //POST

  const createMissionAndVision = async () => {
    try {
      let res = await axios.post("https://students-databse.in/api/about/createmissionvision", { missiontitle, description: Desc });
      if (res.status === 200) {
        handleClose3()
        getMissionAndVision()
        alert('Data Saved..')

      }
      if (res.status === 400) {
        alert("Failed to save mission and vission")
      }
    }
    catch (error) {
      console.log(error)
    }
  }

  //DELETE

  const [selectedObj, setSelectedObj] = useState({})

  const deleteMissionVision = async () => {
    setShow5(false)
    try {
      const res = await axios.delete(`https://students-databse.in/api/about/deletemissionvision/${selectedObj?._id}`)
      if (res.status === 200) {
        alert('Successfully deleted..')
        getMissionAndVision()
      }
      if (res.status === 400) {
        alert('Failed to delete..')
      }
    } catch (error) {
      console.log(error);

    }
  }

  //UPDATE

  const updateMissionVision = async () => {
    setShow5(false)
    try {
      const res = await axios.put(`https://students-databse.in/api/about/updatemissionvision/${editObj?._id}`, {
        missiontitle: editedTitle,
        description: editableDesc
      })
      if (res.status === 200) {
        alert('Successfully Updated..')
        setShow4(false)
        getMissionAndVision()
      }
      if (res.status === 400) {
        alert('Failed to update..')
      }
    } catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {
    getMissionAndVision()
  }, [])

  return (
    <div>
      <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Mission and Vision</h2>
          <div className="d-flex gap-3">
            {
              missionvision.length > 0 ? ('') : (<button className="admin-add-btn" onClick={handleShow3}>
                Add Mission and Vision
              </button>)
            }

            {/* <button className="admin-add-btn" onClick={handleShow3}>
              Add Mission and Vision
            </button> */}

          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Sl.No</th>
                <th>Mission and Vision</th>

                <th>Description</th>

                <th>Action</th>
              </tr>
            </thead>

            <tbody>
              {
                missionvision.map((data, index) => {
                  return (
                    <>
                      <tr>
                        <td>{index + 1}</td>
                        <td>{data?.missiontitle}</td>
                        <td style={{ paddingTop: "10px" }}>
                          {parse(`<div>${data?.description}</div>`)}
                          </td>
                        <td>
                          {" "}
                          <div
                            style={{
                              display: "flex",
                              gap: "20px",
                              justifyContent: "center",
                            }}
                          >
                            <div>
                              <BiSolidEdit
                                className=""
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  color: "rgb(9,122,215)",
                                }}
                                onClick={() => {
                                  handleShow4()
                                  setEditObj(data)
                                  //   setHomeBannerId(item)
                                }

                                }
                              />{" "}
                            </div>
                            <div>
                              <AiFillDelete
                                className=""
                                style={{
                                  cursor: "pointer",
                                  fontSize: "20px",
                                  color: "rgb(172,172,172)",
                                }}
                                onClick={() => {
                                  handleShow5();
                                  setSelectedObj(data)

                                  //   setHomeBannerId(item)
                                  // setDatas(item?._id);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </>
                  )
                })
              }


            </tbody>
          </Table>
        </div>

        {/* Add Package modal for Slider */}

        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Mission and Vision</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  value={missiontitle}
                  onChange={(e) => setMissionTitle(e.target.value)}

                //   onChange={(e) => setBannerText(e.target.value)}

                />
              </div>
            </div>


            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Description</label>

                <CKEditor editor={ClassicEditor} onChange={handleChange} />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
                // onClick={AdminSlider}
                onClick={createMissionAndVision}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}

        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Mission and Vision
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Banner Title</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Banner Title"
                  //   onChange={(e) => setBannerText(e.target.value)}
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                />
              </div>
            </div>


            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Description</label>

                <CKEditor editor={ClassicEditor} data={editableDesc} onChange={handleChange2} />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              //   onClick={EditHomeSlider}
              onClick={updateMissionVision}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delet Package modal for Slider */}

        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              //   onClick={DeleteHomeSlider}
              onClick={deleteMissionVision}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default AdminAboutMissionVision