import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AdminContactDetails = () => {

  // Ck Editor Code (Contact form message desccription)
  
  const [Desc, setDesc] = useState()

  const handleChange = (e, editor) => {
    const data=editor.getData(); 
    setDesc(data) 
  }

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


//Frontend Integration

const [ContactEmail, setContactEmail] = useState("");
const [ContactPhone, setContactPhone] = useState("");
const [ContactAddress, setContactAddress] = useState("");
const [ContactAltaddress, setContactAltaddress] = useState("");

//POST

const createContactDetails = async () => {
  try {
    const config = {
      url: "/createcontactdetails",
      method: "post",
      baseURL: "https://students-databse.in/api/contact",
      headers: { "content-type": "application/json" },
      data: {
        email: ContactEmail,       
        phone: ContactPhone,
        address: ContactAddress,
        altaddress:ContactAltaddress,
      },
    };

    let res = await axios(config);
    console.log("res",res)
    if (res.status === 200) {
      alert(res.data.status);
      handleClose3();
      getContactDetails();
      setContactEmail("");
      setContactPhone("");
      setContactAddress("");
      setContactAltaddress("");
    }
  } catch (error) {
    alert(error.response.data.error);
  }
};

//GET

const [Contactdetails, setContactdetails] = useState([]);

const getContactDetails = async () => {
  try {
    let res = await axios.get("https://students-databse.in/api/contact/getcontactdetails");
    if (res.status === 201) {
      setContactdetails(res.data.data1);
    }
  } catch (error) {
    alert(error.response.data.error); 
  }
};

console.log(Contactdetails);


//UPDATE

const [ContactdetailsId, setContactdetailsId] = useState({})

const editContactDetails = async()=>{

  try {
  const config = {
    url: "/updatecontactdetails/"+ContactdetailsId?._id,
    method: "put",
    baseURL: "https://students-databse.in/api/contact",
    headers: { "content-type": "application/json" },
    data: {
      email: ContactEmail,
      phone: ContactPhone,
      address: ContactAddress,
      altaddress: ContactAltaddress,
    },
  };

  let res = await axios(config);
  if (res.status === 200) {
    alert(res.data.status);
    handleClose4()
    getContactDetails();
    setContactEmail("");
    setContactPhone("");
    setContactAddress("");
    setContactAltaddress("");
  }
}
 catch (error) {
  alert(error.response.data.error);
}
}

//DELETE

const deleteContactDetails = async () => {
  try {
    let res = await axios.delete(`https://students-databse.in/api/contact/deletecontactdetails/${ContactdetailsId?._id}`);
    if (res.status === 200) {
      alert(res.data.status);
      getContactDetails();
      handleClose5()
    }
  } catch (error) {
    alert(error.response.data.status)
  }
};

useEffect(() => {
  getContactDetails();
}, []);


  return (
    <div>
       <div className="customerhead p-2">
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="header-c ">Contact Details</h2>
          <div className="d-flex gap-3">

          {Contactdetails?.length ? (""):(
            <button className="admin-add-btn" onClick={handleShow3}>
              Add Contact Details
            </button>
             )}

          </div>
        </div>

        <div className="mb-3">
          <Table
            responsive
            bordered
            style={{ width: "-webkit-fill-available" }}
          >
            <thead>
              <tr>
                <th>Address</th>
                <th>Alternate Address</th>
                <th>Phone</th>            
                <th>Email</th>               
                <th>Action</th>
              </tr>
            </thead>

            <tbody>
             
            {Contactdetails?.map((item, i) => {
                return (
                  <tr>
                    <td>{item?.address}</td>
                    <td>{item?.altaddress}</td>
                    <td>{item?.phone}</td>                
                    <td style={{ paddingTop: "10px" }}>{item?.email}</td>                   
                    <td>
                      {" "}
                      <div
                        style={{
                          display: "flex",
                          gap: "20px",
                          justifyContent: "center",
                        }}
                      >
                        <div>
                          <BiSolidEdit
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(9,122,215)",
                            }}
                            onClick={() =>{
                              handleShow4()
                              setContactdetailsId(item)
                            }
                               
                              }
                          />{" "}
                        </div>
                        <div>
                          <AiFillDelete
                            className=""
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              color: "rgb(172,172,172)",
                            }}
                            onClick={() => {
                              handleShow5();

                             setContactdetailsId(item)
                             // setDatas(item?._id);
                            }}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>

          </Table>
        </div>

        {/* Add Package modal for Slider */}

        <Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
          <Modal.Header closeButton>
            <Modal.Title>Add Contact Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <div className="row">
              <div className="do-sear mt-2">
                <label>Add Address</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter the Address"
                onChange={(e) => setContactAddress(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Alternate Address</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter the Alternate Address"
                onChange={(e) => setContactAltaddress(e.target.value)}
                />
              </div>
            </div>
          
            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Phone</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter Phone Number"
                  onChange={(e) => setContactPhone(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Add Email</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter the email"
                  onChange={(e) => setContactEmail(e.target.value)}
                />
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <Button
                className="mx-2 modal-close-btn"
                variant=""
                onClick={handleClose3}
              >
                Close
              </Button>
              <Button
                className="mx-2 modal-add-btn"
                variant=""
               onClick={createContactDetails}
              >
                Add
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

        {/* Edit Package modal for Slider */}

        <Modal
          show={show4}
          onHide={handleClose4}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ color: "black" }}>
              Edit Contact Details
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Address</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter the Address"
                 onChange={(e) => setContactAddress(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Alternate Address</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter the Alternate Address"
                onChange={(e) => setContactAltaddress(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Phone</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter the Phone No."
                 onChange={(e) => setContactPhone(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="do-sear mt-2">
                <label>Edit Email</label>
                <input
                  type="text"
                  className="vi_0"
                  placeholder="Enter the Email"
                 onChange={(e) => setContactEmail(e.target.value)}
                />
              </div>
            </div>
           
           
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose4}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={editContactDetails}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        {/*Delete Package modal for Slider */}

        <Modal
          show={show5}
          onHide={handleClose5}
          backdrop="static"
          keyboard={false}
          style={{ zIndex: "99999" }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <p className="fs-4" style={{ color: "red" }}>
                  Are you sure?
                  <br /> you want to delete this data?
                </p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant=""
              className="modal-close-btn"
              onClick={handleClose5}
            >
              Close
            </Button>
            <Button
              variant=""
              className="modal-add-btn"
              onClick={deleteContactDetails}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  )
}

export default AdminContactDetails