import React, { useEffect } from 'react'
import { BrowserRouter,Routes,Route } from 'react-router-dom'

import NavBarTop from "./Components/NavBarTop"
import NavBarMain from "./Components/NavBarMain"

import HomeMain from "./Components/HomeMain"
import About from './Components/About'
import Service from './Components/Service'
import Contact from './Components/Contact'
import Whatsapp from './Components/Whatsapp'
import FooterMain from './Components/FooterMain'

import Aos from 'aos'

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import Main from "./Components/Admin/Main";

import AdminSlider from './Components/Admin/AdminSlider'
import AdminHomeBanner from './Components/Admin/AdminHomeBanner'
import AdminHomeFeature from './Components/Admin/AdminHomeFeature'
import AdminHomeWhyChooseUs from './Components/Admin/AdminHomeWhyChooseUs'
import AdminHeaderScrollingText from './Components/Admin/AdminHeaderScrollingText'
import AdminEnquiryForm from './Components/Admin/AdminEnquiryForm'
import AdminAboutBanner from './Components/Admin/AdminAboutBanner'
import AdminAboutDescription from './Components/Admin/AdminAboutDescription'
import AdminAboutMissionVision from './Components/Admin/AdminAboutMissionVision'
import AdminAboutOurPartners from './Components/Admin/AdminAboutOurPartners'
import AdminServiceBanner from './Components/Admin/AdminServiceBanner'
import AdminServiceDescription from './Components/Admin/AdminServiceDescription'
import AdminServiceCategory from './Components/Admin/AdminServiceCategory'
import AdminServiceCategoryContents from './Components/Admin/AdminServiceCategoryContents'
import AdminContactBanner from './Components/Admin/AdminContactBanner'
import AdminContactDescription from './Components/Admin/AdminContactDescription'
import AdminContactImage from './Components/Admin/AdminContactImage'
import AdminContactDetails from './Components/Admin/AdminContactDetails'
import Product from './Components/Product'
import Project from './Components/Project'
import AdminProjectDescription from './Components/Admin/AdminProjectDescription'
import AdminProjectCategory from './Components/Admin/AdminProjectCategory'
import AdminProjectCategoryContents from './Components/Admin/AdminProjectCategoryContents'

import Login from './Components/Login';
import SignUp from './Components/SignUp';

import AdminGeneralEnquiryForm from './Components/Admin/AdminGeneralEnquiryForm'


const App = () => {

 useEffect(() => {
   
  Aos.init({
    offset: 200,
    duration: 600,
    easing: 'ease-in-sine',
    delay: 100,
  });
 }, [])
 
  return (

 <BrowserRouter>

  <Routes>

      <Route path="/" element={
        <> 
        <NavBarTop/> 
        <NavBarMain/> 
        <HomeMain/>
        <Whatsapp/>
        <FooterMain/>    
        </>       
        } />


     <Route path="/about" element={
      <>
        <NavBarTop/> 
        <NavBarMain/> 
        <About/>
        <Whatsapp/>
        <FooterMain/>
        </>
     } />


    <Route path="/service" element={
          <>
        <NavBarTop/> 
        <NavBarMain/> 
        <Service/>
        <Whatsapp/>
        <FooterMain/>
        </>
     } />


    {/* <Route path="/product" element={
          <>
        <NavBarTop/> 
        <NavBarMain/> 
        <Product/>
        <Whatsapp/>
        <FooterMain/>
        </>
     } /> */}


     <Route path="/project" element={
          <>
        <NavBarTop/> 
        <NavBarMain/> 
        <Project/>
        <Whatsapp/>
        <FooterMain/>
        </>
     } />


    <Route path="/contact" element={
              <>
            <NavBarTop/> 
            <NavBarMain/> 
            <Contact/>
            <Whatsapp/>
            <FooterMain/>
            </>
        } />

         {/* Login */}

         <Route path="/login" element={<>

            <Login />

         </>} />


        {/*Sign up */}

        <Route path="/signup" element={<>

        <SignUp />

        </>} />

     {/*======== DASHBOARD ===========*/}

      {/* HOME */}

     <Route
            path="/admin_slider"
            element={
              <Main
                children={
                  <>
                    <AdminSlider />
                  </>
                }
              />
            }
          />

    <Route
            path="/admin_homebanner"
            element={
              <Main
                children={
                  <>
                   <AdminHomeBanner/>
                  </>
                }
              />
            }
          />

          
    <Route
            path="/admin_homefeature"
            element={
              <Main
                children={
                  <>
                   <AdminHomeFeature/>
                  </>
                }
              />
            }
          />

               
    <Route
            path="/admin_whychooseus"
            element={
              <Main
                children={
                  <>
                   <AdminHomeWhyChooseUs/>
                  </>
                }
              />
            }
          />

      <Route
            path="/admin_scrollingtext"
            element={
              <Main
                children={
                  <>
                   <AdminHeaderScrollingText/>
                  </>
                }
              />
            }
          />


        <Route
            path="/admin_enquiryform"
            element={
              <Main
                children={
                  <>
                   <AdminEnquiryForm/>
                  </>
                }
              />
            }
          />

        <Route
            path="/admin_generalenquiryform"
            element={
              <Main
                children={
                  <>
                   <AdminGeneralEnquiryForm/>
                  </>
                }
              />
            }
          />
            
    {/* ABOUT */}


    <Route
            path="/admin_aboutbanner"
            element={
              <Main
                children={
                  <>
                   <AdminAboutBanner/>
                  </>
                }
              />
            }
          />

      <Route
            path="/admin_aboutdescription"
            element={
              <Main
                children={
                  <>
                   
                   <AdminAboutDescription/>
                  </>
                }
              />
            }
          />

      <Route
            path="/admin_aboutmissionvision"
            element={
              <Main
                children={
                  <>
                   
                  <AdminAboutMissionVision/>
                  </>
                }
              />
            }
          />

        <Route
            path="/admin_aboutourpartners"
            element={
              <Main
                children={
                  <>
                   
                  <AdminAboutOurPartners/>
                  </>
                }
              />
            }
          />

          {/* SERVICE */}

          <Route
            path="/admin_servicebanner"
            element={
              <Main
                children={
                  <>
                   
                  <AdminServiceBanner/>
                  </>
                }
              />
            }
          />

        <Route
            path="/admin_servicedescription"
            element={
              <Main
                children={
                  <>
                   
                  <AdminServiceDescription/>
                  </>
                }
              />
            }
          />


        <Route
            path="/admin_servicecategory"
            element={
              <Main
                children={
                  <>
                   
                 <AdminServiceCategory/>
                  </>
                }
              />
            }
          />


        <Route
            path="/admin_servicecategorycontents"
            element={
              <Main
                children={
                  <>
                   
                <AdminServiceCategoryContents/>
                  </>
                }
              />
            }
          />

          {/* PROJECT */}

          <Route
            path="/admin_projectdescription"
            element={
              <Main
                children={
                  <>
                   
                  <AdminProjectDescription/>
                  </>
                }
              />
            }
          />

        <Route
            path="/admin_projectcategory"
            element={
              <Main
                children={
                  <>                  
                <AdminProjectCategory/>
                  </>
                }
              />
            }
          />


          <Route
            path="/admin_projectcategorycontents"
            element={
              <Main
                children={
                  <>                  
                <AdminProjectCategoryContents/>
                  </>
                }
              />
            }
          />


          {/* CONTACT */}


          <Route
            path="/admin_contactbanner"
            element={
              <Main
                children={
                  <>                  
                <AdminContactBanner/>
                  </>

                }
              />
            }
          />


          <Route
            path="/admin_contactdescription"
            element={
              <Main
                children={
                  <>                  
               <AdminContactDescription/>
                  </>
                  
                }
              />
            }
          />


          
          <Route
            path="/admin_contactimage"
            element={
              <Main
                children={
                  <>                  
                <AdminContactImage/>
                  </>
                  
                }
              />
            }
          />

      <Route
            path="/admin_contactdetails"
            element={
              <Main
                children={
                  <>                  
                <AdminContactDetails/>
                  </>
                  
                }
              />
            }
          />

</Routes>
</BrowserRouter>

    
  )
}

export default App