import React, { useState, useEffect } from "react";
import { Button, Modal, Table, Image } from "react-bootstrap";
import { AiFillDelete } from "react-icons/ai";
import { BiSolidEdit } from "react-icons/bi";
import { BsSearch } from "react-icons/bs";
import "../Admin/Admin.css";
import axios from "axios";
import parse from "html-react-parser";

const AdminServiceCategory = () => {

     // Ck Editor Code
  const [Desc, setDesc] = useState()

  const handleChange = (e, editor) => {
    const data=editor.getData(); 
    setDesc(data) 
  }

  // Add modal for Slider
  const [show3, setShow3] = useState();
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // Edit modal for  Slider
  const [show4, setShow4] = useState();
  const handleClose4 = () => setShow4(false);
  const handleShow4 = () => setShow4(true);

  // Delete modal for  Slider
  const [show5, setShow5] = useState();
  const handleClose5 = () => setShow5(false);
  const handleShow5 = () => setShow5(true);


//Frontend Integration

const [BannerCategoryText, setBannerCategoryText] = useState("");

//EDIT

const [AboutCategoryId, setAboutCategoryId] = useState({})
const [editupdate, seteditupdate] = useState("")

useEffect(()=>{
  seteditupdate(AboutCategoryId?.servicetext)
},[AboutCategoryId])

const editAboutCategory = async()=>{
  try {
  const config = {
    url: "/updateservicecategory/"+AboutCategoryId?._id,
    method: "put",
    baseURL: "https://students-databse.in/api/service",
    headers: { "content-type": "application/json" },
    data: { 
      servicetext:editupdate  
    },
  };


  let res = await axios(config);
  if (res.status === 201) {
    alert("Category Updated Successfully..");
    handleClose4()  
    getServiceCategory()
    setBannerCategoryText("");
   
  }
}
 catch (error) {
  alert(error.response.data.error);
}
}

//DELETE

const deleteAboutCategory = async () => {
  try {
    let res = await axios.delete(`https://students-databse.in/api/service/deleteservicecategory/${AboutCategoryId?._id}`);
    if (res.status === 201) {
      setShow5(false)
      alert("Category deleted Successfully..");
      getServiceCategory();
      
    }
  } catch (error) {
    alert(error.response.data.message)
  }
};


const [category,setCategory]=useState('')
const [categorylist,setCategoryList]=useState([])

console.log(categorylist);


useEffect(() => {
  getServiceCategory()
}, [])

//POST

const saveServicecategory=async()=>{
  try{
    let res = await axios.post("https://students-databse.in/api/service/createservicecategory",{servicetext:category});
    if (res.status === 200) {
      handleClose3()
      getServiceCategory();
      alert('Category Saved..')
      setCategory('')
   
    }
    if (res.status === 400) {
      alert("Failed to save category")
    }
  }
  catch(error){
    console.log(error)
  }
}

//GET

const getServiceCategory=async()=>{
  try{
    let res = await axios.get("https://students-databse.in/api/service/getservicecategory");
    if (res.status === 201) {
      setCategoryList(res.data.data1);
    }
    if (res.status === 400) {
      alert("Failed to fetch Category")
    }
    if (res.status === 500) {
      alert("Something went wrong")
    }
  }
  catch(error){
    console.log(error)
  }
}

  return (
    <div>  <div className="customerhead p-2">
    <div className="d-flex justify-content-between align-items-center">
<h2 className="header-c ">Category</h2>
<div className="d-flex gap-3">
 <button className="admin-add-btn" onClick={handleShow3}>
   Add Category 
 </button>
</div>

</div>

<div className="mb-3">
<Table
 responsive
 bordered
 style={{ width: "-webkit-fill-available" }}
>
 <thead>
   <tr>
     <th></th>
     <th>Category</th>
    
     <th>Action</th>
   </tr>
 </thead>
 <tbody>

     {
categorylist.map((data,index)=>{
return(
  <tr>
     <td>{index+1}</td>
     <td style={{ paddingTop: "10px" }}>{data?.servicetext}</td>
     
     <td>
       {" "}
       <div
         style={{
           display: "flex",
           gap: "20px",
           justifyContent: "center",
         }}
       >
         <div>
           <BiSolidEdit
             className=""
             style={{ cursor: "pointer", fontSize: "20px", color:"rgb(101, 177, 69)" }}
             onClick={() => {handleShow4();
               setAboutCategoryId(data)
              }
             }

           />{" "}
         </div>
         <div>
           <AiFillDelete
             className=""
             style={{ cursor: "pointer", fontSize: "20px", color:"rgb(25, 107, 156)" }}
             onClick={() => {
               handleShow5();
               setAboutCategoryId(data)
             }}
           />
         </div>
       </div>
     </td>
   </tr>
)
})
}
   
 {/* );
})} */}
 </tbody>
</Table>
</div>

{/* Add Package modal for Slider */}
<Modal show={show3} onHide={handleClose3} style={{ zIndex: "99999" }}>
 <Modal.Header
   closeButton
 >
   <Modal.Title>Add Category </Modal.Title>
 </Modal.Header>
 <Modal.Body>
   
   

   <div className="row">
     <div className="do-sear mt-2">
       <label>Add Category Name</label>
       <input
         type="text"
         className="vi_0"
         placeholder="Enter Category Name"
        // onChange={(e) => setBannerCategoryText(e.target.value)}
        value={category}
        onChange={(e)=>setCategory(e.target.value)}
       />
     </div>
   </div>
   

 </Modal.Body>
 <Modal.Footer>
   <div className="d-flex">
     <Button
       className="mx-2 modal-close-btn"
       variant=""
       onClick={handleClose3}
     >
       Close
     </Button>
     <Button
       className="mx-2 modal-add-btn"
       variant=""
  // onClick={postAboutCategory}
  onClick={saveServicecategory}
     >
       Add
     </Button>
   </div>
 </Modal.Footer>
</Modal>

{/* Edit Package modal for Slider */}
<Modal
 show={show4}
 onHide={handleClose4}
 backdrop="static"
 keyboard={false}
 style={{ zIndex: "99999" }}
>
 <Modal.Header
   closeButton
 >
   <Modal.Title style={{ color: "black" }}>Edit Category </Modal.Title>
 </Modal.Header>

 <Modal.Body>
  


   <div className="row">
     <div className="do-sear mt-2">
       <label>Edit Caterory Title</label>
       <input
         type="text"
         className="vi_0"
         value={editupdate}
         placeholder="Enter Category Name"
        onChange={(e) => seteditupdate(e.target.value)}
       />
     </div>
   </div>


   

 </Modal.Body>
 <Modal.Footer>
   <Button
     variant=""
     className="modal-close-btn"
     onClick={handleClose4}
   >
     Close
   </Button>
   <Button variant="" className="modal-add-btn"
   onClick={editAboutCategory}
   >
     Update
   </Button>
 </Modal.Footer>
</Modal>

{/*Delet Package modal for Slider */}
<Modal
 show={show5}
 onHide={handleClose5}
 backdrop="static"
 keyboard={false}
 style={{ zIndex: "99999" }}
>
 <Modal.Header
   closeButton
 >
   <Modal.Title>Warning</Modal.Title>
 </Modal.Header>
 <Modal.Body>
   <div className="row">
     <div className="col-md-12">
       <p className="fs-4" style={{ color: "red" }}>
         Are you sure?
         <br /> you want to delete this data?
       </p>
     </div>
   </div>
 </Modal.Body>
 <Modal.Footer>
   <Button
     variant=""
     className="modal-close-btn"
     onClick={handleClose5}
   >
     Close
   </Button>
   <Button
     variant=""
     className="modal-add-btn"
   onClick={deleteAboutCategory}
   >
     Delete
   </Button>
 </Modal.Footer>
</Modal>
</div>
        
    </div>
  )
}

export default AdminServiceCategory