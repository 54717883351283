import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from 'axios'

//Passing productid through props to the enquiry form so that the details of product id should be displayed in admin panel

const EnqForm = ({ productid }) => {
console.log("productid", productid);

//POST (Enquiry Form)

  const [show, setShow] = useState(false);

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [ServiceCat, setServiceCat] = useState("")
  const [address, setAddress] = useState('')
  const [message, setMessage] = useState('')

  const ServiceEnquiryForm = async () => {
    try {

      if (!name) {
        alert("Please Enter Your Name.")
        return;
      }      
      if (!phone) {
        alert("Please Enter Your Phone Number.")
        return;
      }
      if (!email) {
        alert("Please Enter Your Email Id.")
        return;
      }
      if (!ServiceCat) {
        alert("Please Select Category.")
        return;
      }
      if (!address) {
        alert("Please Enter Address.")
        return;
      }
      if (!message) {
        alert("Please Enter Message.")
        return;
      }

      let res = await axios
        .post("https://students-databse.in/api/enquiryform/createenquiryform", 
          { ServiceCat:ServiceCat,name, phone, email, address: address, description: message, productid: productid });
    
          if (res.status === 200) {
        setShow(false)
        alert('Thank you for reaching us.')
        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
        setAddress('')
      }
      else if (res.status === 400) {
        alert('Can not add the details, something went wrong')
      }
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <Button
        variant=""
        className="Any-Enquiry"
        style={{ color: "white" }}
        onClick={() => setShow(true)}
      >
        Enquiry Now
      </Button>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        size="lg"
      >

        <Modal.Header closeButton>
          <Modal.Title id="example-custom-modal-styling-title">
            <p className="enquiry-form-heading">Enquiry Form</p>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>

          <div>
            <h5 className="mt-2 mb-2">Please Fill the Form Below!</h5>
          </div>

          <div className="pt-4">
            <div className="row mb-3">

              <div className="col-md-6">
                <label htmlFor="sel" className="form-label">
                  Enquiry Type
                </label>
                
                <select               
                onChange={(e)=>setServiceCat(e.target.value)}
                name="sel" id="sel" className="form-select">
                  <option value="">Select</option>
                  <option value="services">Services</option>
                  <option value="product">Product</option>
                </select>
              </div>

              <div className="col-md-6">
                <label htmlFor="name" className="form-label">
                  Name
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control"
                  id="name"
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6">
                <label htmlFor="phone" className="form-label">
                  Phone
                </label>
                <input
                  type="tel"
                  placeholder="Enter Phone Number"
                  className="form-control"
                  id="phone"
                  required
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>

              <div className="col-md-6">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  placeholder="Enter Email"
                  className="form-control"
                  id="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label htmlFor="serviceName" className="form-label">
                  Location
                </label>
                <input
                  type="text"
                  placeholder="Enter the location"
                  className="form-control"
                  id="serviceName"
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-12">
                <label htmlFor="description" className="form-label">
                  Description
                </label>

                <input
                  type="text"
                  placeholder="Enter the Message"
                  className="form-control"
                  id="text"
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </div>
            </div>


            <div className="d-flex justify-content-end">

              <button className="btn btn-primary me-2" onClick={ServiceEnquiryForm}>Submit</button>

              <button
                className="btn btn-secondary"
                onClick={() => setShow(false)}
              >
                Cancel
              </button>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  );
};

export default EnqForm;
